


























































import Vue from "vue";
import {
  DisplayCard,
  loadCartItems,
  removeCartItem,
  updateQuantity,
} from "../helpers/common";

export default Vue.extend({
  name: "CartDrawer",
  props: {
    open: Boolean,
    close: {
      type: Function,
    },
    isSwitchedCustom: Boolean,
  },

  data() {
    return {
      overlay: false,
      fullheight: true,
      fullwidth: false,
      right: true,
      isSwitchedCustoms: this.isSwitchedCustom,
      number: 1,
    };
  },
  mounted() {
    this.$store.commit("setCart", loadCartItems());
  },
  methods: {
    trashItems(itemToRemove: DisplayCard) {
      removeCartItem(this.$store, this.$store.state.cart, itemToRemove);
    },
    updateQuantity(itemToUpdate: DisplayCard, quantity: number) {
      updateQuantity(
        this.$store,
        this.$store.state.cart,
        itemToUpdate,
        quantity
      );
    },
  },
});
