import Vue from "vue";
import VueCookies from "vue-cookies";
import App from "./App.vue";
import router from "./router/router";
import store from "./store/store";
import Buefy from "buefy";
import { auth } from "./firebase";
import "buefy/dist/buefy.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import "./assets/style.scss";
// internal icons
import {
  faCheck,
  faCheckCircle,
  faInfoCircle,
  faExclamationTriangle,
  faExclamationCircle,
  faArrowUp,
  faAngleRight,
  faAngleLeft,
  faAngleDown,
  faEye,
  faEyeSlash,
  faCaretDown,
  faCaretUp,
  faUpload,
  faPlusCircle,
  faSearchPlus,
  faTrashAlt,
  faPlus,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faCheck,
  faCheckCircle,
  faInfoCircle,
  faExclamationTriangle,
  faExclamationCircle,
  faArrowUp,
  faAngleRight,
  faAngleLeft,
  faAngleDown,
  faEye,
  faEyeSlash,
  faCaretDown,
  faCaretUp,
  faUpload,
  faPlusCircle,
  faSearchPlus,
  faTrashAlt,
  faPlus,
  faMinus
);
Vue.component("vue-fontawesome", FontAwesomeIcon);

import BmcHeader from "@/components/BmcHeader.vue";
import AddressForm from "@/components/AddressForm.vue";
import ImageBox from "@/components/ImageBox.vue";

Vue.use(Buefy, {
  defaultIconComponent: "vue-fontawesome",
  defaultIconPack: "fas",
});
Vue.component("BmcHeader", BmcHeader);
Vue.component("AddressForm", AddressForm);
Vue.component("ImageBox", ImageBox);

Vue.config.productionTip = false;

let app: Vue;
auth.onAuthStateChanged((user) => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  }

  if (user) {
    store.dispatch("login", user);
  }
});
Vue.use(VueCookies, { expire: "1d" });
