import { db } from "@/firebase";
import firebase from "firebase";
import { Store } from "vuex";

export interface Card {
  name: string;
  displayName: string;
  set: string;
  displaySet: string;
  game: string;
  rarity: string | null;
  displayRarity: string | null;
  number: number | null;
  displayNumber: number | null;
  buyPrice: number | null;
  quantity: number | null;
  condition: number;
  uploadTimestamp?: firebase.firestore.Timestamp;
  uploadId: number | null;
  uploadedDbId?: string;
  docRef?: firebase.firestore.DocumentReference;
  matched?: boolean;
}

export interface AliasCard extends Card {
  nameAlias?: string;
  setAlias?: string;
  rarityAlias?: string | null;
  numberAlias?: number | null;
  image?: string;
  dbId?: string;
}

export interface CardInfo {
  useAlias?: boolean;
  uploadedDbId?: string;
  aliasDbId?: string;
  card: AliasCard;
}

export interface TableRow {
  card: string;
  game: string;
  set: string;
  rarity: string;
  number: string;
  condition: string;
  buy_price: string;
  quantity: string;
}

export interface Draft {
  uploadedOn: string;
  formattedDate: string;
  ref: firebase.firestore.DocumentReference;
}

export interface DisplayCard {
  title: any;
  imgSrc: any;
  image: string;
  name: string;
  description: string;
  price: number;
  id: number;
  quantity: number;
}

export interface SearchResults {
  id: string;
  name: string;
  set: string;
  rarity: string;
  number: number;
}

const CONDITIONS = [
  "Near Mint",
  "Lightly Played",
  "Moderately Played",
  "Heavily Played",
  "Damaged",
  "Unknown",
];

export const uploadData = async (storeId: any, fileData: any) => {
  console.log("Uploading data");
  // Get store or create if it doesn't exist
  const storesRef = db.collection("stores");
  const getStore = await storesRef.where("storeId", "==", storeId).get();
  let storeDoc;
  if (getStore.empty) {
    // Store doesnt exist, create it
    storeDoc = storesRef.doc(storeId);
    storeDoc.set({ storeId: storeId });
  } else {
    // Store exists, retreive it
    storeDoc = getStore.docs[0].ref;
  }

  // Creating a new upload
  const uploadTimestamp = firebase.firestore.Timestamp.now();
  const uploadsRef = storeDoc.collection("uploads");
  const uploadDoc = await uploadsRef.add({
    uploadedOn: uploadTimestamp,
    uploadVerified: false,
  });

  // Uploading cards
  const uploadCardsRef = uploadDoc.collection("uploadedCards");
  const uploadRef = uploadCardsRef.parent;
  if (uploadRef != null) {
    uploadRef.update({ totalCards: fileData.length });
  }
  let batch = db.batch();
  let count = 0;
  let total = 0;
  let completedBatches = 0;
  fileData.forEach((row: TableRow) => {
    const card = fileToDbConverter(row, uploadTimestamp);
    card.uploadId = count;
    const cardDoc = uploadCardsRef?.doc();
    batch.set(cardDoc, card);
    count += 1;
    total += 1;
    if (count === 500 || total === fileData.length) {
      batch.commit().then(() => {
        completedBatches += 1;
      });
      count = 0;
      batch = db.batch();
    }
  });
  return uploadCardsRef;
};

export const getUploadDoc = (uploadRef: any, uploadCallback: any) => {
  return uploadRef.onSnapshot((documentSnapshots: { docs: any[] }) =>
    uploadCallback(documentSnapshots)
  );
};

const fileToDbConverter = (
  row: TableRow,
  uploadTimestamp: firebase.firestore.Timestamp
) => {
  // TODO rename edition to rarity
  const card: Card = {
    name: row.card,
    displayName: row.card,
    set: row.set,
    displaySet: row.set,
    game: row.game != null ? row.game : "",
    rarity: row.rarity != null ? row.rarity : null,
    displayRarity: row.rarity != null ? row.rarity : null,
    number: row.number != null ? Number(row.number) : null,
    displayNumber: row.number != null ? Number(row.number) : null,
    buyPrice: row.buy_price != null ? Number(row.buy_price) : null,
    quantity: row.quantity != null ? Number(row.quantity) : null,
    condition: 5,
    uploadTimestamp: uploadTimestamp,
    uploadId: null,
  };
  if (row.condition != null) {
    for (const idx in CONDITIONS) {
      if (
        row.condition
          .trim()
          .toLowerCase()
          .includes(CONDITIONS[idx].trim().toLowerCase())
      ) {
        card.condition = parseInt(idx) + 1;
        break;
      } else {
        card.condition = 1;
      }
    }
  }
  return card;
};

export const DbToRowConverter = (
  entry: firebase.firestore.QueryDocumentSnapshot
): Card => {
  return {
    name: entry.get("name"),
    displayName:
      entry.get("nameAlias") == null
        ? entry.get("name")
        : entry.get("nameAlias"),
    game: entry.get("game"),
    set: entry.get("set"),
    displaySet:
      entry.get("setAlias") == null ? entry.get("set") : entry.get("setAlias"),
    rarity: entry.get("rarity"),
    displayRarity:
      entry.get("rarityAlias") == null
        ? entry.get("rarity")
        : entry.get("rarityAlias"),
    number: entry.get("number"),
    displayNumber:
      entry.get("numberAlias") == null
        ? entry.get("number")
        : entry.get("numberAlias"),
    condition: entry.get("condition"),
    buyPrice: entry.get("buyPrice"),
    quantity: entry.get("quantity"),
    matched: entry.get("matched"),
    uploadId: entry.get("uploadId"),
    uploadedDbId: entry.id,
    docRef: entry.ref,
  };
};

export const checkForDups = (fileData: any) => {
  let dupsFound = false;
  const cardSets = fileData.map(
    (item: { card: string; set: string; condition: string; number: number }) =>
      item.card.toLowerCase() +
      "~" +
      item.set.toLowerCase() +
      "~" +
      item.condition.toLowerCase() +
      "~" +
      item.number
  );
  fileData = fileData.filter((_item: any, index: number) => {
    if (cardSets.indexOf(cardSets[index], index + 1) == -1) {
      return true;
    }
    dupsFound = true;
    return false;
  });
  return { dupsFound, fileData };
};

export const onEditInventoryPageChange = (
  cardsRef: firebase.firestore.CollectionReference,
  currentPage: number,
  nextPage: any,
  sortField: any,
  sortOrder: any,
  firstDoc: any,
  lastDoc: any,
  snapshotCallback: any,
  limit: number
) => {
  return loadDataFromDb(
    currentPage,
    nextPage,
    cardsRef,
    sortField,
    sortOrder,
    firstDoc,
    lastDoc,
    snapshotCallback,
    limit
  );
};

const loadDataFromDb = (
  currentPage: number,
  nextPage: number,
  cardsRef: firebase.firestore.CollectionReference,
  sortField: string,
  sortOrder: firebase.firestore.OrderByDirection,
  firstDoc: firebase.firestore.DocumentReference,
  lastDoc: firebase.firestore.DocumentReference,
  snapshotCallback: any,
  limit: number
) => {
  let unsubscribe;
  if (nextPage > currentPage) {
    console.log("next page");
    const query = cardsRef
      .orderBy(sortField, sortOrder)
      .startAfter(lastDoc)
      .limit(limit);
    unsubscribe = query.onSnapshot((documentSnapshots: { docs: any[] }) =>
      snapshotCallback(documentSnapshots)
    );
  } else if (nextPage < currentPage) {
    console.log("prev page");
    const query = cardsRef
      .orderBy(sortField, sortOrder)
      .endBefore(firstDoc)
      .limitToLast(limit);
    unsubscribe = query.onSnapshot((documentSnapshots: { docs: any[] }) =>
      snapshotCallback(documentSnapshots)
    );
  } else {
    let query;
    if (firstDoc) {
      console.log("same page, exists");
      query = cardsRef
        .orderBy(sortField, sortOrder)
        .startAt(firstDoc)
        .limit(limit);
    } else {
      console.log("same page, no exists");
      query = cardsRef.orderBy(sortField, sortOrder).limit(limit);
    }
    unsubscribe = query.onSnapshot((documentSnapshots: { docs: any[] }) =>
      snapshotCallback(documentSnapshots)
    );
  }
  return unsubscribe;
};

export const updateCard = async (
  updatedCardInfo: CardInfo,
  dbRef: firebase.firestore.CollectionReference,
  storeId: string
) => {
  const batch = db.batch();
  const uploadedCardRef = dbRef.doc(updatedCardInfo.uploadedDbId); // uploadedCards
  batch.set(uploadedCardRef, updatedCardInfo.card, { merge: true });
  if (updatedCardInfo.useAlias) {
    // Find card
    const cardRef = db.collection("pokemon").doc(updatedCardInfo.aliasDbId);
    // Find alias for card/store
    const aliasRef = cardRef.collection("aliases");
    const aliasQuerySnapshot = await aliasRef
      .where("storeId", "==", storeId)
      .get();
    let aliasDoc;
    if (aliasQuerySnapshot.empty) {
      aliasDoc = null;
    }
    if (aliasQuerySnapshot.size > 1) {
      throw "Multiple aliases for card!";
    }

    if (aliasQuerySnapshot.docs[0]) {
      // Overwritting alias
      // TODO warn user
      aliasDoc = aliasQuerySnapshot.docs[0].ref;
    } else {
      aliasDoc = aliasRef.doc();
    }

    const aliasInfo = {
      nameAlias: updatedCardInfo.card.nameAlias,
      numberAlias: updatedCardInfo.card.numberAlias,
      rarityAlias: updatedCardInfo.card.rarityAlias,
      setAlias: updatedCardInfo.card.setAlias,
    };

    const trueInfo = {
      game: updatedCardInfo.card.game,
      name: updatedCardInfo.card.name,
      number: updatedCardInfo.card.number,
      rarity: updatedCardInfo.card.rarity,
      set: updatedCardInfo.card.set,
      storeId: storeId,
      timestamp: firebase.firestore.Timestamp.now(),
    };
    batch.set(aliasDoc, { ...trueInfo, ...aliasInfo }, { merge: true });
  }
  batch.commit();
};

export const getDrafts = async (storeId: string) => {
  const uploadedDrafts = await db
    .collection("stores")
    .doc(storeId)
    .collection("uploads")
    .orderBy("uploadedOn", "desc")
    .limit(1)
    .get();

  const drafts: Draft[] = [];

  for (const idx in uploadedDrafts.docs) {
    const date = uploadedDrafts.docs[idx].get("uploadedOn").toDate();
    let formattedDate =
      date.getFullYear() +
      "-" +
      date.getMonth() +
      1 +
      "-" +
      date.getDate() +
      " " +
      date.getHours() +
      ":";

    let minutes = date.getMinutes().toString();
    if (minutes.length == 1) {
      minutes = "0" + minutes;
    }
    formattedDate = formattedDate + minutes;
    drafts.push({
      uploadedOn: date.toString(),
      formattedDate: formattedDate,
      ref: uploadedDrafts.docs[idx].ref,
    });
  }
  return drafts;
};

export const deleteRows = async (
  deletedRows: Card[],
  dbRef: firebase.firestore.CollectionReference,
  currentSize: number
) => {
  const batch = db.batch();
  for (const idx in deletedRows) {
    const docRef = deletedRows[idx].docRef;
    if (docRef) {
      batch.delete(docRef);
    }
  }
  batch.commit();
  const uploadRef = dbRef.parent;
  if (uploadRef != null) {
    uploadRef.update({ totalCards: currentSize - deletedRows.length });
  }
};

export const getCount = (
  docId: string,
  snapshotCallback: (snapshot: firebase.firestore.QuerySnapshot) => void
): (() => void) => {
  // Sum the count of each shard in the subcollection
  return db
    .collection("counters")
    .doc(docId)
    .collection("shards")
    .onSnapshot(snapshotCallback);
};

export const markVerified = (dbRef: firebase.firestore.CollectionReference) => {
  const uploadDoc = dbRef.parent;
  if (uploadDoc != null) {
    uploadDoc.update({ uploadVerified: true });
  }
};

// Returns true if all cards in dbRef are verified
export const allVerified = async (
  dbRef: firebase.firestore.CollectionReference
): Promise<boolean> => {
  const allVerifiedSnapshot = await dbRef.where("matched", "==", false).get();
  if (allVerifiedSnapshot.empty) {
    return true;
  }
  return false;
};

export const getLiveCollection = (
  storeId: string
): Promise<
  firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
> => {
  const liveRef = db.collection("stores").doc(storeId).collection("live");
  return liveRef.orderBy("timestamp", "desc").limit(1).get();
};

export const loadCartItems = (): DisplayCard[] => {
  const lastUpdatedString = localStorage.getItem("lastUpdated");
  if (!lastUpdatedString) {
    return [];
  }

  // If storage hasn't been updated in 30 days, clear it
  const lastUpdatedDate = new Date(lastUpdatedString);
  const now = new Date();
  const timeSinceUpdate = Math.abs(now.getTime() - lastUpdatedDate.getTime());
  if (timeSinceUpdate > 2592000) {
    localStorage.clear();
    return [];
  }
  const cartItems = localStorage.getItem("cart");
  if (cartItems) {
    try {
      return JSON.parse(cartItems);
    } catch (e) {
      localStorage.removeItem("cart");
      return [];
    }
  }
  return [];
};

export const saveCartItems = (cartItems: DisplayCard[]): void => {
  const parsedCart = JSON.stringify(cartItems);
  localStorage.setItem("cart", parsedCart);
  const parsedDate = JSON.stringify(new Date());
  localStorage.setItem("lastUpdated", parsedDate);
};

export const removeCartItem = (
  store: Store<any>,
  cartItems: DisplayCard[],
  itemToRemove: DisplayCard
): void => {
  for (let i = 0; i < cartItems.length; i++) {
    if (cartItems[i].id == itemToRemove.id) {
      cartItems.splice(i, i + 1);
      saveCartItems(cartItems);
      break;
    }
  }
  store.commit("setCart", cartItems);
};

export const updateQuantity = (
  store: Store<any>,
  cartItems: DisplayCard[],
  itemToUpdate: DisplayCard,
  quantity: number
): void => {
  let matched = false;
  cartItems.some((element) => {
    if (element.id === itemToUpdate.id) {
      element.quantity = quantity;
      return (matched = true);
    }
  });
  if (!matched) {
    // error
  }
  saveCartItems(cartItems);
  store.commit("setCart", cartItems);
};
