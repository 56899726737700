<template>
  <div class="columns is-multiline">
    <div class="column">
      <b-image :src="src" :alt="desc" ratio="600by825"></b-image>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ImageBox extends Vue {
  @Prop(String) src;
  @Prop(String) desc;
}
</script>

<style></style>
