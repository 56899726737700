








































import { Vue } from "vue-property-decorator";

export default class AddressForm extends Vue {
  countries = [{ country: "Canada" }, { country: "USA" }];
  states = [{ state: "MD" }, { state: "ON" }];
}
