import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import store from "./store/store";
import * as firebaseui from "firebaseui";
import router from "./router/router";
import "firebase/firestore";

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: "AIzaSyBW32uhNbsK9oI14e3_ZkGmNxuSBBag4r4",
  authDomain: "yugioh-6bedc.firebaseapp.com",
  databaseURL: "https://yugioh-6bedc-default-rtdb.firebaseio.com",
  projectId: "yugioh-6bedc",
  storageBucket: "yugioh-6bedc.appspot.com",
  messagingSenderId: "16620978153",
  appId: "1:16620978153:web:6f43b18d682d17d0605578",
  measurementId: "G-YRQJ6B7YFQ",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// utils
const db = firebase.firestore();
// db.useEmulator("localhost", 8081);
const auth = firebase.auth();
// auth.useEmulator("http://localhost:9099");
const func = firebase.functions();
// func.useEmulator("localhost", 5001);

// firebaseui
// firebaseUi configs
const uiConfig = {
  callbacks: {
    signInSuccessWithAuthResult: function (
      authResult: firebase.auth.UserCredential
    ): boolean {
      if (
        authResult.additionalUserInfo &&
        authResult.additionalUserInfo.isNewUser
      ) {
        if (store.state.vind == 0) {
          router.push("/profile-setup");
          return true;
        } else {
          router.push("/vendor-signup");
          return true;
        }
      } else {
        router.push("/");
        return true;
      }
      return false;
    },
    uiShown: function (): void {
      console.log("Firebase UI widget rendered");
    },
    signInFailure: function (error: firebase.FirebaseError): void {
      alert("Sign in failed. See dev console for error response");
      console.log("error", error);
    },
  },
  // signInSuccessUrl: "/",
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  ],
};

// firebase ui object
const ui = new firebaseui.auth.AuthUI(auth);

// export utils/refs
export { db, auth, uiConfig, ui, func };
