import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { auth } from "../firebase";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Home.vue"),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/vendor-access",
    name: "VendorAccess",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/VendorAccess.vue"),
  },
  {
    path: "/vendor-signup",
    name: "VendorSignUp",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/VendorSignUp.vue"),
  },
  {
    path: "/signin",
    name: "SignIn",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/FirebaseAuthentication.vue"
      ),
  },
  {
    path: "/user-profile",
    name: "UserProfileEdit",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/UserProfileEdit.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/vendor-profile",
    name: "VendorProfileEdit",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/VendorProfileEdit.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cart",
    name: "Cart",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Cart.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/search",
    name: "SearchPage",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SearchPage.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/edit-inventory",
    name: "InventoryEdit",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/EditInventory.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);

  if (requiresAuth && !auth.currentUser) {
    next("/signin");
  } else {
    next();
  }
});

export default router;
