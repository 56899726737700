














































































import { Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import CartDrawer from "./CartDrawer.vue";

export default Vue.extend({
  components: {
    CartDrawer,
  },
  computed: {
    ...mapGetters(["cartTotalQuantity"]),
  },
  data() {
    return {
      open: false,
      isSwitchedCustom: false,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
    sidebarHandle() {
      this.open = !this.open;
    },
    close() {
      if (!this.isSwitchedCustom) {
        this.open = false;
      }
    },
    isVendor() {
      if (
        this.$store.state.vind == 1 &&
        this.$route.path != "/vendor-profile"
      ) {
        return this.$router.push("/vendor-profile");
      }
      if (this.$store.state.vind == 0 && this.$route.path != "/user-profile") {
        return this.$router.push("/user-profile");
      }
    },
    isLoggedOn() {
      if (this.$store.state.uid) {
        return true;
      }
      return false;
    },
  },
});
