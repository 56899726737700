import Vue from "vue";
import Vuex from "vuex";
import firebase from "firebase/app";
import { auth } from "@/firebase";
import { DisplayCard } from "@/helpers/common";

Vue.use(Vuex);

const store = new Vuex.Store({
  strict: false,
  state: {
    uid: null,
    vendorEmail: null,
    vind: 0,
    cart: [] as DisplayCard[],
  },

  mutations: {
    setVind(state, val) {
      state.vind = val;
    },
    updateVendorEmail(state, vendorEmail) {
      state.vendorEmail = vendorEmail;
    },
    setCart(state, updatedCart) {
      state.cart = updatedCart;
    },
    setUserId(state, uid) {
      state.uid = uid;
    },
  },
  actions: {
    login({ commit }, user) {
      commit("setUserId", user.uid);
      user
        .getIdTokenResult()
        .then((idTokenResult: firebase.auth.IdTokenResult) => {
          if (idTokenResult.claims.vendor) {
            commit("setVind", 1);
            return;
          }
          commit("setVind", 0);
        })
        .catch((error: firebase.auth.Error) => {
          console.log("Login error", error);
        });
    },
    logout({ commit }) {
      auth
        .signOut()
        .then(() => {
          commit("setUserId", null);
          commit("setVind", 0);
        })
        .catch((error) => {
          console.log("Logout error", error);
        });
    },
    async updateUserDisplayName({ commit }, userDisplayName) {
      await auth.currentUser?.updateProfile({ displayName: userDisplayName });
      commit("setUserDisplayName", userDisplayName);
    },
  },

  getters: {
    cartTotalQuantity: (state) => {
      let quantity = 0;
      state.cart.forEach((element) => {
        quantity += element.quantity;
      });
      return quantity;
    },
  },

  modules: {},
});

export default store;
